


import { Vue, Component } from 'vue-property-decorator';
import EditEvent from '@/_modules/events/components/edit-event/edit-event.vue';

@Component({
  components: {
    EditEvent,
  }
})
export default class CabinetMainPage extends Vue {
}
