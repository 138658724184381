type TWindowWithMapsCallbacks = {
  EventsWalletGoogleMapsInit: () => void;
  EventsWalletBaiduMapsInit: () => void;
}

type TWindowWithBaiduMapsInitialized = {
  BMap: any;
}

export const startGoogleMaps = function(): Promise<object> {
  return new Promise((resolve) => {
    const GoogleMapsApiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
    const scriptId = 'EventsWalletGoogleMapsScript';

    const callBackFunctionName: keyof TWindowWithMapsCallbacks = 'EventsWalletGoogleMapsInit';
    const apiUrl = `https://maps.googleapis.com/maps/api/js?v=weekly&libraries=places&key=${GoogleMapsApiKey}&callback=${callBackFunctionName}`;

    ((window as unknown) as TWindowWithMapsCallbacks)[callBackFunctionName] = resolve;

    if (document.getElementById(scriptId)) {
      resolve();
    } else {
      const s = document.createElement('script');
      s.setAttribute('src', apiUrl);
      s.setAttribute('id', scriptId);

      document.body.appendChild(s);
    }
  });
};

export const startBaiduMaps = function(): Promise<object> {
  return new Promise((resolve) => {
    const BaiduMapsApiKey = process.env.VUE_APP_BAIDU_MAPS_API_KEY;
    const scriptId = 'EventsWalletBaiduMapsScript';
    const callBackFunctionName: keyof TWindowWithMapsCallbacks = 'EventsWalletBaiduMapsInit';

    ((window as unknown) as TWindowWithMapsCallbacks)[callBackFunctionName] = function(): void {
      resolve(((window as unknown) as TWindowWithBaiduMapsInitialized).BMap);
    };

    if (!document.getElementById(scriptId)) {
      const s = document.createElement('script');
      s.setAttribute('id', scriptId);
      s.setAttribute('src', `https://api.map.baidu.com/api?v=2.0&ak=${BaiduMapsApiKey}&callback=${callBackFunctionName}`);

      document.getElementsByTagName('head')[0].appendChild(s);
    } else if ('BMap' in window) {
      resolve(((window as unknown) as TWindowWithBaiduMapsInitialized).BMap);
    }

  });

};
